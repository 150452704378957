<template>
  <div class="bg-co-gray-200 px-4 py-12 sm:py-[4.25rem] lg:px-0">
    <div class="co-container-sm bg-white p-9 text-center shadow-[0_0_4px_rgba(51,51,51,0.2)] md:p-12">
      <h1 class="co-display-6 mb-20 mt-5 font-bold">
        404エラー<br>ページが見つかりません
      </h1>
      <p class="co-display-12 mb-10">
        大変申し訳ありません。
        <br>
        お探しのページは見つかりませんでした。
      </p>
      <a
        class="co-btn-primary"
        href="/"
      >
        TOPページへ
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { scriptTagsByConfig } from '~/utility/scriptTags'
import { metaTags } from '~/utility/constants/metaTags'

const options = {
  oldHead() {
    const scriptTags = scriptTagsByConfig({
      useCase: 'index',
    })
    return {
      title: 'ページが見つかりませんでした | 受講者数No.1のテックアカデミー',
      meta: [
        ...metaTags(
          {
            ogUrl: '/errors/404',
            noIndexNofollow: true,
          },
          process.env.host as string
        ),
      ],
      script: scriptTags!.script,
      noscript: scriptTags!.noscript,
    }
  },
}

export default defineNuxtComponent(options)
</script>
